import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 10 min. `}<meta itemProp="prepTime" content="PT10M" /></h5>
    <h5>{`Cook time: 60 min. `}<meta itemProp="cookTime" content="PT60M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`3-4`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Jasmine rice, 2 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Coconut milk, 1 can</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Water, up to the "3 cup" line</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Turmeric, 2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Bay leaves, 2</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Lemongrass, 1 stalk</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt, 1/2 tsp.</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Rice cooker</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Tie lemongrass into a knot</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Combine all ingredients in the rice cooker.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cook on a white rice setting.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      